import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import kebabCase from 'lodash/kebabCase';
import { GatsbyImage } from 'gatsby-plugin-image';
import trimEnd from 'lodash/trimEnd';
import trimStart from 'lodash/trimStart';
import { DiscussionEmbed } from 'disqus-react';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import {
    forSmallDevices,
    forExtraSmallDevices
} from '../utils/styles';

const Content = styled.div`
    padding: 20px 20px 20px 260px;

    &::before,
    &::after {
        content: "";
        display: table;
    }

    &::after {
        clear: both;  
    }

    ${forSmallDevices(`
        padding: 20px 10px;
    `)}

    ${forExtraSmallDevices(`
        padding: 10px;
    `)}
`;

const Post = styled.article`
    background-color: ${({ theme }) => theme.colors.white};
`;

const CoverImageWrapper = styled.div`
    position: relative;
    padding: 0;
    margin: 0;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.lightGray};
    overflow: hidden;
`;

const CoverImageFigure = styled.figure`
    flex: 1;
    position: relative;
    margin: 0;
`;

const CoverImage = styled(GatsbyImage)`
    width: 100%;
    height: 100%;
`;

const PostContent = styled.div`
    padding: 20px;
`;

const PostHeader = styled.header` 
    text-align: center;
    margin: 10px 0;
`;

const PostTitle = styled.h1` 
    font-weight: 400;
    margin: 0 0 10px;
    line-height: 1.3;
`;

const PostDate = styled.div`
    color: ${({ theme }) => theme.colors.darkGray};
    font-weight: 300;
    font-size: 12px;
    text-transform: uppercase;
`;

const PostRenderedContent = styled.div`
    h3 {
        margin: 0.6em 0 0.3em 0;
    }

    h4 {
        margin: 0.6em 0 0.1em 0;
    }

    h5 {
        margin: 0.6em 0 0.1em 0;
    }

    p {
        margin: 0;
    }

    ul {
        margin: 0;
    }

    ol {
        margin: 0;
    }
`;

const PostFooter = styled.div` 
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 30px;
    margin: 30px 0  ;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.darkGray}`};
`;

const FooterTags = styled.div` 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const FooterTag = styled.span` 
    font-size: 10px;
    padding: 5px 10px;
    margin-left: 5px;
    text-transform: uppercase;
    border: ${({ theme }) => `1px solid ${theme.colors.darkBlue}`};
    white-space: nowrap;
`;

const FooterTagLink = styled(Link)` 
    text-decoration: none;
    color: ${({ theme }) => theme.colors.darkBlue};

    &:hover {
        text-decoration: underline;
        opacity: 0.8;
    }
`;

const PostTemplate = ({
    data: {
        page: {
            name,
            slug,
            cover,
            date,
            tags,
            summary,
            childMdx: {
                body
            }
        },
        site: {
            siteMetadata: {
                siteUrl
            }
        }
    }
}) => {
    const coverImageSrc = cover?.image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;
    const metaImage = coverImageSrc && siteUrl ?
        `${trimEnd(siteUrl, '/')}/${trimStart(coverImageSrc, '/')}` :
        undefined;
    return (
        <Layout>
            <Seo
                title={name}
                description={summary}
                keywords={tags ?? []}
                image={metaImage}
            />
            <Content>
                <Post>
                    <div>
                        {cover && (
                            <CoverImageWrapper>
                                <CoverImageFigure>
                                    <CoverImage
                                        image={
                                            cover.image.childImageSharp.gatsbyImageData
                                        }
                                        key={coverImageSrc ?? ''}
                                        alt=''
                                    />
                                </CoverImageFigure>
                            </CoverImageWrapper>
                        )}
                        <PostContent>
                            <PostHeader>
                                <PostTitle>{name}</PostTitle>
                                <PostDate>
                                    <span>{date}</span>
                                </PostDate>
                            </PostHeader>
                            <PostRenderedContent>
                                <MDXRenderer>{body}</MDXRenderer>
                            </PostRenderedContent>
                            <PostFooter>
                                <FooterTags>
                                    {tags &&
                                        tags.map(tag => (
                                            <FooterTag key={tag}>
                                                <FooterTagLink to={`/tags/${kebabCase(tag)}/`}>
                                                    #
                                                    {' '}
                                                    {tag}
                                                </FooterTagLink>
                                            </FooterTag>
                                        ))}
                                </FooterTags>
                            </PostFooter>
                            <DiscussionEmbed
                                shortname={process.env.GATSBY_DISQUS_NAME}
                                config={{ identifier: slug, title: name }}
                            />
                        </PostContent>
                    </div>
                </Post>
            </Content>
        </Layout>
    );
};

export default PostTemplate;

export const pageQuery = graphql`
  query Post($path: String!) {
    page: googleDocs(slug: {eq: $path}) {
      name
      slug
      date(formatString: "YYYY, MMM DD")
      cover {
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, formats: [AUTO, AVIF, WEBP])
          }
        }
      }
      tags
      summary
      childMdx {
        body
      }
    }
    site {
        siteMetadata {
            siteUrl
        }
    }
  }
`;
